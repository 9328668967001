<style scoped>
</style>

<template>
  <div class="vue-box">
    <!-- 参数栏 -->
    <div class="c-panel">
      <div class="c-title">二次验证器绑定</div>
      <el-form size="mini" v-if="data" v-show="data.isBind == false">
        <el-form-item label="密钥:">
          <el-input style="width: 300px;" disabled v-model="data.secretKey"></el-input>
        </el-form-item>
        <el-form-item label="二维码:">
          <el-image style="width: 200px; height: 200px" :src="data.url"></el-image>
        </el-form-item>
        <el-form-item label="验证code">
          <el-input style="width: 300px;" v-model="data.code"></el-input>
        </el-form-item>
        <el-form-item>
          <span class="c-label">&emsp;</span>
          <el-button type="primary" icon="el-icon-check" size="mini" @click="checkCode">绑定</el-button>
        </el-form-item>
      </el-form>
      <el-result icon="success" title="当前已绑定登录二次验证器" v-show="data.isBind == true"></el-result>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        secretKey: '',
        url: '',
        code: '',
        isBind: true
      }
    }
  },
  methods: {
    getSecretKey: function () {
      var defaultCfg2 = {
        type: "get", //是否是请求体请求
      };
      this.sa.ajax('/v1/merchant/getSecretKey', function (res) {
        this.data.secretKey = res.data;
        this.data.url = this.sa.cfg.api_url +
          "/v1/merchant/getQrcode?secretKey=" + this.data.secretKey + "&name=" + this.sa_admin.mchId
      }.bind(this), defaultCfg2)
    },
    isBind: function () {
      var defaultCfg2 = {
        type: "get", //是否是请求体请求
      };
      this.sa.ajax('/v1/merchant/isBindGoogle', function (res) {
        if (res.data == '未绑定google验证器') {
          this.data.isBind = false;
          this.getSecretKey();
        } else {
          this.data.isBind = true;
        }
      }.bind(this), defaultCfg2)
    },
    checkCode: function () {
      var defaultCfg2 = {
        type: "get", //是否是请求体请求
      };
      var p = {
        code: this.data.code,
        secretKey: this.data.secretKey
      };
      this.sa.ajax('/v1/merchant/checkCode', p, function () {
        this.sa.ok("绑定成功");
      }.bind(this), defaultCfg2)
    }
  },
  created() {
    this.isBind();
  }
}
</script>


